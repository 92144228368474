import {apiBase, ApiResponse, fetchUsingApi} from "@/api/rest/api.ts";
import dayjs, {Dayjs} from "@/components/dayjsExtended.ts";

export interface WarrantyCaseRaw {
    id: number;
    householdId: number;
    title: string;
    status: WarrantyCaseStatus;
    createdAt: string;
}

export interface WarrantyCase extends WarrantyCaseRaw {
    createdAtTimestamp: Dayjs;
    localisedStatus: string;
}

export enum WarrantyCaseStatus {
    QUESTION_SENT = "QUESTION_SENT",
    RESPONSE_RECEIVED = "RESPONSE_RECEIVED",
    SOLVED = "SOLVED",
    CLOSED = "CLOSED",
}

const statusMap = new Map<WarrantyCaseStatus, string>([
    [WarrantyCaseStatus.QUESTION_SENT, "warranty.caseStatus.questionSent"],
    [WarrantyCaseStatus.RESPONSE_RECEIVED, "warranty.caseStatus.responseReceived"],
    [WarrantyCaseStatus.SOLVED, "warranty.caseStatus.solved"],
    [WarrantyCaseStatus.CLOSED, "warranty.caseStatus.closed"],
]);

export interface WarrantyCaseMessageRaw {
    id: number;
    warrantyCaseId: number;
    accountId: number;
    userId: number;
    createdAt: string;
    content: string;
    type: WarrantyCaseMessageType,
}

export interface WarrantyCaseMessage extends WarrantyCaseMessageRaw {
    createdAtTimestamp: Dayjs;
}

export enum WarrantyCaseMessageType {
    TEXT = "TEXT",
    FILE = "FILE",
    IMAGE = "IMAGE",
    AI_HISTORY = "AI_HISTORY",
}

export interface WarrantyCaseResponse {
    case: WarrantyCase;
    messages: WarrantyCaseMessage[];
}

const enrichWarrantyCaseResponse = (response: WarrantyCaseResponse): WarrantyCaseResponse => {
    return {
        case: enrichWarrantyCase([response.case])[0],
        messages: enrichWarrantyCaseMessages(response.messages),
    };
}

const enrichWarrantyCaseMessage = (m: WarrantyCaseMessageRaw): WarrantyCaseMessage => {
    return {
        ...m,
        createdAtTimestamp: dayjs(m.createdAt),
    };
}

const enrichWarrantyCaseMessages = (messages: WarrantyCaseMessageRaw[]): WarrantyCaseMessage[] => {
    return messages.map(enrichWarrantyCaseMessage);
}

const enrichWarrantyCase = (cases: WarrantyCaseRaw[]): WarrantyCase[] => {
    return cases.map(c => ({
        ...c,
        createdAtTimestamp: dayjs(c.createdAt),
        localisedStatus: statusMap.get(c.status) ?? c.status.toString(),
    }));
}

export const getAllCases = async (householdId: number): Promise<ApiResponse<WarrantyCase[]>> => {
    return await fetchUsingApi(`${apiBase}/warranty/v1/list?householdId=${householdId}`, 'GET', enrichWarrantyCase);
}

export const getAllCasesForBuilding = async (buildingId: number): Promise<ApiResponse<WarrantyCase[]>> => {
    return await fetchUsingApi(`${apiBase}/warranty/v1/list/building?buildingId=${buildingId}`, 'GET', enrichWarrantyCase);
}

export const getCase = async (caseId: number): Promise<ApiResponse<WarrantyCaseResponse>> => {
    return await fetchUsingApi(`${apiBase}/warranty/v1/${caseId}/get`, 'GET', enrichWarrantyCaseResponse);
}

export const sendMessage = async (caseId: number, request: WarrantyMessageRequest): Promise<ApiResponse<WarrantyCaseMessage>> => {
    return await fetchUsingApi(`${apiBase}/warranty/v1/${caseId}/message`, 'POST', enrichWarrantyCaseMessage, JSON.stringify(request));
}

export const sendFiles = async (caseId: number, accountId: number, files: File[]): Promise<ApiResponse<WarrantyCaseMessage[]>> => {
    const formData = new FormData();
    formData.append('accountId', accountId.toString());
    files.forEach(file => {
        formData.append('files', file);
        formData.append('fileNames', file.path);
    });
    return await fetchUsingApi(`${apiBase}/warranty/v1/${caseId}/files`, 'POST', enrichWarrantyCaseMessages, formData);
}

export interface WarrantyMessageRequest {
    accountId: number;
    message: string;
}

export interface NewWarrantyCaseRequest {
    question: string;
    householdId: number;
    startFresh: boolean;
    accountId: number;
}

export interface SubmitWarrantyCaseRequestWithoutFiles {
    title: string,
    description: string;
    householdId: number;
    accountId: number;
    phoneNumber: string | null;
}

export interface NewWarrantyCaseResponse {
    status: NewWarrantyCaseResponseType;
    message: string;
    caseId: number | null;
    provisionalTitle: string | null;
}

export enum NewWarrantyCaseResponseType {
    MORE_INFO_REQUIRED = "MORE_INFO_REQUIRED",
    WARRANTY = "WARRANTY",
    NO_WARRANTY = "NO_WARRANTY",
}

export const fileNewCase = async (request: NewWarrantyCaseRequest): Promise<ApiResponse<NewWarrantyCaseResponse>> => {
    return await fetchUsingApi(`${apiBase}/warranty/v1/new`, 'POST', (e) => e as NewWarrantyCaseResponse, JSON.stringify(request));
}

export const forceSubmitCase = async (request: SubmitWarrantyCaseRequestWithoutFiles, files: File[]): Promise<ApiResponse<WarrantyCaseRaw>> => {
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => formData.append(key, (value as (string | number)).toString()));
    files.forEach(file => {
        formData.append('files', file);
        formData.append('fileNames', file.path);
    });
    return await fetchUsingApi(`${apiBase}/warranty/v1/submit`, 'POST', (e) => e as WarrantyCaseRaw, formData);
}
