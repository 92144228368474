import {Bars4Icon, HomeModernIcon} from "@heroicons/react/24/outline";
import {UserCircleIcon} from "@heroicons/react/24/solid";
import {Fragment, useCallback, useState} from "react";
import {useAccount} from "../api/hooks/useAccount.ts";
import DobuLogo from "../assets/dobu-logo.svg";
import EmbachLogo from "../assets/partner-logos/embach.svg";
import LivenLogo from "../assets/partner-logos/liven.svg";
import HepsorLogo from "../assets/partner-logos/hepsor.svg";
import ReterraLogo from "../assets/partner-logos/reterra.svg";
import RaemoisaLogo from "../assets/partner-logos/raemoisa.svg";
import DorpatLogo from "../assets/partner-logos/dorpat.svg";
import CapitalMillLogo from "../assets/partner-logos/capital-mill.svg";
import {clsx} from "clsx";
import {useNavigate, useParams} from "react-router-dom";
import {useHousehold} from "@/api/hooks/useHousehold.ts";
import {HouseholdData} from "@/api/rest/api.ts";
import {useEnrichedHouseholds} from "@/api/hooks/useEnrichedHouseholds.ts";
import {logout} from "@/api/rest/authApi.ts";
import { useTranslation } from 'react-i18next';

export default function Navbar() {
    const account = useAccount();
    const navigate = useNavigate();
    const asda = useEnrichedHouseholds();
    const { t } = useTranslation();

    const currentHousehold = useParams<{ householdId: string }>()
    const [currentHouseholdData, ] = useHousehold(currentHousehold ? Number(currentHousehold.householdId) : null)

    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = useCallback(() => setIsOpen((state) => !state), []);

    const navigateTo = useCallback((path: string) => {
        setIsOpen(false);
        navigate(path);
    }, [navigate]);

    const logoutClick = useCallback(async () => {
        await logout()
    }, [])

    return (
        <nav className="min-w-[240px] flex flex-col justify-stretch bg-white">
            <Logo householdData={currentHouseholdData} toggleIsOpen={toggleIsOpen} />
            <div
                className={clsx(
                    "md:flex flex-col justify-stretch pb-8 md:overflow-y-scroll scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 md:flex-grow",
                    isOpen ? "flex" : "hidden"
                )}>
                {account && <span
                    className="px-4 py-2 font-bold text-lg cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus"
                    onClick={() => navigateTo(`/account`)}>
                    <UserCircleIcon className="h-6 w-6 inline-block -mt-1 mr-2"/>
                    {account?.name}
                </span>}
                {asda.map((building) => (
                    <Fragment key={building.id}>
                        <span
                            className="px-4 py-2 font-bold text-lg cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus"
                            onClick={() => {building.id != -1 ? navigateTo(`/building/${building.id}`) : navigateTo(`/household`)}}
                        >
                            <HomeModernIcon className="h-6 w-6 inline-block -mt-1 mr-2"/>
                            {building.id != -1 ? building.street : t("home.myResidences")}
                        </span>
                        <ul>
                            {building.households.map((household) => (
                                <li className="pl-12 pr-8 py-1 cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-dobu-orange-focus"
                                    key={household.id}
                                    onClick={() => navigateTo(`/household/${household.id}`)}>{household.title}</li>
                            ))}
                        </ul>
                    </Fragment>
                ))}
                <span className="hidden md:flex flex-grow" />
                <div className="flex flex-row justify-center mt-3">
                    <button className="rounded-md px-4 py-1 !border-dobu-black border-2 !bg-white hover:!bg-gray-200 !text-dobu-black text-sm" onClick={logoutClick}>{t("login.logout")}</button>
                </div>
            </div>
        </nav>
    )
}

const LogoMappings = {
    'EMBACH': [EmbachLogo],
    'LIVEN': [LivenLogo],
    'HEPSOR': [HepsorLogo],
    'RETERRA': [ReterraLogo],
    'RETERRA_RAEMOISA': [ReterraLogo, RaemoisaLogo],
    'CAPITAL_MILL': [CapitalMillLogo],
    'DORPAT': [DorpatLogo],
}

const Logo = ({householdData, toggleIsOpen}: { householdData: HouseholdData | undefined, toggleIsOpen: () => void }) => {
    const navigate = useNavigate();
    const logoMappings = householdData?.building.partnerCode && LogoMappings[householdData?.building.partnerCode as keyof typeof LogoMappings]
    const companyLogo = logoMappings && logoMappings[0]
    const developmentLogo = logoMappings && logoMappings[1]
    return (
        <div className="flex flex-col mt-8 mb-4 gap-2 items-stretch md:items-center">
            <div className="flex flex-row justify-between items-center">
                <Bars4Icon className="h-10 w-10 ml-6 p-1 inline md:hidden invisible"/>
                <img className="invert mx-4 self-center" src={DobuLogo} alt={"Logo"} width={100} height={24} onClick={() => navigate('/household')}/>
                {companyLogo && <img className="hidden sm:flex md:hidden mx-4 self-center" src={companyLogo} alt={"CompanyLogo"} width={100}
                     height={24}/>}
                {companyLogo && <Bars4Icon
                    className="h-10 min-w-10 md:min-w-0 w-10 mr-6 p-1 inline md:hidden cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus rounded md:invisible"
                    onClick={toggleIsOpen}
                />}
                {developmentLogo && <img className="hidden sm:flex md:hidden mx-4 self-center" src={developmentLogo} alt={"DevelopmentLogo"} width={100}
                                     height={24}/>}
                {developmentLogo &&<Bars4Icon
                    className="h-10 min-w-10 md:min-w-0 w-10 mr-6 p-1 inline md:hidden cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus rounded md:invisible"
                    onClick={toggleIsOpen}
                />}
            </div>
            {companyLogo && <hr className="hidden md:flex border-[0.5px] border-solid border-gray-300 rounded-lg w-1/2"/>}
            {companyLogo && <div className="hidden md:flex flex-row justify-between items-center">
                <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible"/>
                <img className="mx-4 self-center" src={companyLogo} alt={"Logo"} width={100} height={24}/>
                <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible"/>
            </div>}
            {developmentLogo && <div className="hidden md:flex flex-row justify-between items-center">
                <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible"/>
                <img className="mx-4 self-center" src={developmentLogo} alt={"Logo"} width={100} height={24}/>
                <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible"/>
            </div>}
        </div>
    )
}
